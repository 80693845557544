@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Manrope", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  min-height: 100vh;
  max-width: 100vw;
}

#vmsPrice {
  color: #e53d54;
  margin-top: 48vh;
  text-align: center;
}

.vms-KPI-heading {
  color: #e53d54;
  font-size: 1.3rem;
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
}
.vms-KPI-description {
  color: #737373;
  width: 100%;
  font-size: 1.1rem;
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
}

.circle {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 450px;
  height: 450px;
  padding: 1rem 1rem;
}

.circle-heading {
  max-width: 300px;
  font-weight: 600;
}

.circle-para {
  max-width: 80%;
  text-align: justify;
  font-size: 0.9rem;
}

.circle-name {
  max-width: 300px;
  font-weight: 800;
}

.button-white {
  background: white;
  border-radius: 5px;
  color: #e54058;
  font-weight: bold;
  padding: 1rem 3rem;
  border: none;
}

.button-white:hover {
  outline: none;
  color: white;
  background: black;
}

.button-red {
  background: #e54058;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  padding: 1rem 3rem;
  border: none;
}
.button-red:hover {
  outline: none;
  background: black;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#bg-div-dealer {
  position: fixed;
  top: 30vh;
  height: 100vh;
  width: 100%;
  background: linear-gradient(264.02deg, #e33e52 9.45%, #ff001f 95.99%);
  clip-path: polygon(0 25%, 100% 13%, 100% 87%, 0 75%);
}

.dealer-input {
  background: #edf0f7;
  border-radius: 2px;
  padding: 1rem 1rem;
  width: 100%;
  border: none;
  margin: 0.5rem 0rem;
}

#dealerHandImage {
  width: 40%;
  position: fixed;
  top: 22vh;
  left: 10vh;
  z-index: -200;
}

.waButton {
  position: fixed;
  width: 3.5rem;
  height: 3.5rem;
  bottom: 2rem;
  right: 2rem;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 2rem;
  /* box-shadow: 2px 2px 3px #999; */
  z-index: 100;
}

button:focus {
  outline: 0;
}

input:focus {
  outline: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea:focus {
  outline: 0;
}

.cursor-pointer {
  cursor: pointer !important;
}

button {
  cursor: pointer !important;
}

input {
  outline: 0;
}

#navbar {
  height: 5vh;
}

#brandlogo {
  width: 13rem;
}
.getQuoteButton {
  transition: all 0.2s ease;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  padding: 0.3rem 2rem;
  border: solid transparent 2px;
  background-color: rgba(229, 61, 84, 1);
  border-radius: 25px;
}

.getaQuoteHero {
  transition: all 0.2s ease;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  padding: 0.3rem 2rem;
  border: solid transparent 2px;
  background-color: rgba(229, 61, 84, 1);
  border-radius: 25px;
}

.blogTile:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.getQuoteButton:hover {
  transition: all 0.2s ease;
  background-color: rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 1);
}

.getaQuoteHero:hover {
  transition: all 0.2s ease;
  background-color: rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 1);
}

#caseStudyButton {
  transition: all 0.2s ease;
  font-weight: 600;
  color: rgba(229, 61, 84, 1);
  padding: 0.3rem 2rem;
  border: solid rgba(229, 61, 84, 1) 2px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 25px;
}

#caseStudyButton:hover {
  transition: all 0.2s ease;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  padding: 0.3rem 2rem;
  border: solid rgba(229, 61, 84, 1) 2px;
  background-color: rgba(229, 61, 84, 1);
  border-radius: 25px;
}

#caseStudyButtonTransparent {
  transition: all 0.2s ease;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  padding: 0.3rem 2rem;
  border-radius: 25px;
  background-color: transparent;
  border: solid rgba(255, 255, 255, 1) 2px;
}

#caseStudyButtonTransparent:hover {
  transition: all 0.2s ease;
  font-weight: 600;
  color: black;
  padding: 0.3rem 2rem;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 1);
  border: solid rgba(255, 255, 255, 1) 2px;
}

#heroHeading {
  position: relative;
  font-family: "Manrope", sans-serif;
  z-index: 2;
  top: 0;
  text-shadow: 2px 2px 8px #333333;
}

.trustedImages {
  object-fit: contain;
}

.greyScale {
  filter: grayscale(100%);
}

.imgOpacity {
  filter: opacity(50%);
}

.blockHeading {
  font-size: 1.7rem;
  font-weight: bold;
  margin-top: 5rem;
  margin-bottom: 1.5rem;
}

.blockDescription {
  font-size: 1.3rem;
  font-weight: lighter;
  margin-bottom: 1.5rem;
}

.bulletActive {
  padding: 20px 20px;
  margin: 0;
  background-color: rgba(229, 61, 84, 1);
  border-radius: 50px;
  color: whitesmoke;
}
.bulletInactive {
  padding: 20px 20px;
  margin: 0;
  border-radius: 50px;
  color: black;
}

.smallInput {
  border-radius: 0;
  border-width: 1px;

  background-color: rgba(255, 255, 255, 1);
  color: #383838;
  border-style: solid;
  border-color: rgba(244, 244, 244, 1);
  padding: 3px;
  margin: 0;
  width: 100%;
  height: 5rem;
  outline: none;
}

.smallInput:hover {
  background-color: rgba(51, 46, 46, 0.075);
  border: 2px solid rgba(244, 244, 244, 0.87);
}

.SuccessButton {
  transition: all 0.2s ease;
  background-color: rgba(66, 197, 136, 1);
  color: white;
  border-radius: 3rem;
  border: 1px solid grey;
  font-weight: bold;
}

.SuccessButton:hover {
  transition: all 0.2s ease;
  background-color: rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 1);
}

.contactTitle {
  color: #383838;
  font-size: 2rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
}

.contactDescription {
  color: #383838;
  font-size: 1.5rem;
  font-weight: 200;
  line-height: normal;
  letter-spacing: normal;
}

.contactUsButton {
  transition: all 0.2s ease;
  transition: color 0.4s ease 0s;
  color: #42c588;
  display: inline-block;
}

.contactUsButton:hover {
  transition: all 0.2s ease;
  color: white;
  background-color: black;
}

#popup {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.767);
  z-index: 10;
}
.popupDialouge {
  background-color: rgba(255, 255, 255, 1);
  /* padding: 2rem 1rem; */
}
.popupButton {
  transition: all 0.2s ease;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  padding: 0.3rem 2rem;
  border: solid black 2px;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 25px;
}

.popupButton:hover {
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 1);
}

.carousel .slide {
  background: none !important ;
  text-align: left !important;
}

.slider-wrapper {
  transition: none !important;
}

.carousel .control-dots .dot {
  background: red !important;
}
/* .control-dots{
  display : none !important
} */
.modalCloseButton {
  transition: all 0.2s ease;
  color: grey;
  padding: 0.3rem 2rem;
  border: solid grey 2px;
  background-color: transparent;
  border-radius: 25px;
}

.modalCloseButton:hover {
  transition: all 0.2s ease;
  color: white;
  background-color: black;
}

.popupInput {
  color: rgb(139, 135, 135);
  text-align: left;
  border-radius: 25px;
  border: 0.5px solid rgb(139, 135, 135);
  padding: 0.5rem 1rem;
}

.donwloadButton {
  transition: all 0.2s ease;
  color: black;
  border: solid black 2px;
  background-color: transparent;
  padding: 1rem 2rem;
  border-radius: 50px;
}
.subscribeInput {
  color: grey;
  text-align: center;
  border-radius: 25px;
  padding: 0.5rem 1rem;
  border: 0.5px solid rgb(139, 135, 135);
  display: block;
}
.carousel .control-dots {
  position: relative !important;
  margin: 20px 0px;
}

.popupButton {
  width: 100%;
}

.donwloadButton:hover {
  transition: all 0.2s ease;
  color: white;
  border: solid white 2px;
  background-color: black;
  padding: 1rem 2rem;
  border-radius: 50px;
}

@media (max-width: 575.98px) {
  /* .button-red {
    background: white;
    border-radius: 5px;
    color: #e54058;
    font-weight: bold;
    padding: 1rem 3rem;
    border: none;
  }  */

  .blogCarausel {
    display: none;
  }
  .campaign-paragraph {
    font-size: 1.2rem;
  }
  .waButton {
    position: fixed;
    width: 4rem;
    height: 4rem;
    bottom: 2rem;
    right: 2rem;
    background-color: #25d366;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    /* box-shadow: 2px 2px 3px #999; */
    z-index: 100;
  }

  .waIcon {
    font-size: 2rem;
  }

  .popupDialouge {
    width: 90%;
    padding: 4rem 5rem;
  }

  .justify-content-xs-center {
    justify-content: center;
  }

  .fullWidth {
    font-size: 1.2rem;
    width: 250px !important;
    padding: 1rem 1rem !important;
  }

  .hero-text {
    width: 90%;
  }
  .popupDesc {
    font-size: 1.6rem;
  }
  .popupInput {
    width: 100%;
  }

  .subscribeInput {
    width: 100%;
  }

  .carousel img {
    width: 80px !important;
  }

  #heroHeading {
    font-size: 35px;
  }

  #heroSubtitle {
    font-size: 20px;
  }
  .getaQuoteHero {
    width: 180px;
  }
  /* .carousel .slide{ background: none !important ;
    text-align : center !important;
  } */

  .priceHeading {
    font-size: 1rem;
  }

  .popupFormContainer {
    width: 100%;
  }
}

.text-md-odd {
  justify-content: flex-start;
}
.text-md-even {
  justify-content: flex-end;
}

.landing-headings {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

.carausel-slide-bg {
  border-radius: 65px;
}

.landing-paras {
  font-size: 1.3rem;
}

.cloud-images-thumbs-odd {
  width: 70%;
  margin-left: auto;
}

.cloud-images-thumbs-even {
  width: 70%;
  margin-right: auto;
}

.lp-top-row {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 120vh;
}

.benifit-text-lp{
  font-size: 1.1rem;
}

@media (max-width: 767.98px) {
  .carausel-slide-bg {
    border-radius: 0px;
  }
 .benifit-text-lp{
  font-size: 1.4rem;
}
  .row-md-reverse {
    flex-direction: column-reverse;
  }
  .text-md-odd {
    text-align: center;
    justify-content: center;
  }
  .text-md-even {
    text-align: center;
    justify-content: center;
  }
  .cloud-images-thumbs-odd {
    width: 80%;
    margin: auto auto;
  }

  .cloud-images-thumbs-even {
    width: 80%;
    margin: auto auto;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  #vmsPrice {
    color: #e53d54;
    margin-top: 6vh;
    text-align: center;
  }
  .campaign-paragraph {
    font-size: 1.5rem;
  }
  .blogCarausel {
    display: none;
  }

  .popupDialouge {
    width: 80%;
    padding: 4rem 5rem;
  }

  .waButton {
    position: fixed;
    width: 4rem;
    height: 4rem;
    bottom: 2rem;
    right: 2rem;
    background-color: #25d366;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    /* box-shadow: 2px 2px 3px #999; */
    z-index: 100;
  }

  .waIcon {
    font-size: 3rem;
  }

  .hero-text {
    width: 90%;
  }

  .popupDesc {
    font-size: 1.8rem;
  }
  .popupInput {
    width: 65%;
  }
  .subscribeInput {
    width: 100%;
  }

  .carousel .slide {
    position: static !important;
  }

  #heroHeading {
    font-size: 38px;
  }
  .fullWidth {
    font-size: 1.5rem;
    width: 250px !important;
    padding: 1rem 1rem !important;
  }
  #heroSubtitle {
    font-size: 2rem;
  }

  .priceHeading {
    font-size: 1.2rem;
  }

  .popupFormContainer {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .blogNavigators {
    display: none;
  }

  .waButton {
    position: fixed;
    width: 3.5rem;
    height: 3.5rem;
    bottom: 2rem;
    right: 2rem;
    background-color: #25d366;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 2rem;
    /* box-shadow: 2px 2px 3px #999; */
    z-index: 100;
  }

  .popupDialouge {
    width: 80%;
    padding: 4rem 5rem;
  }

  .hero-text {
    width: 90%;
  }

  .popupDesc {
    font-size: 1.5rem;
  }
  .popupInput {
    width: 60%;
  }
  .subscribeInput {
    width: 100%;
  }

  #heroHeading {
    font-size: 2.5rem;
  }
  #heroSubtitle {
    font-size: 1.5rem;
  }

  .popupFormContainer {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .blogNavigators {
    display: none;
  }

  .popupDialouge {
    width: 70%;
    padding: 4rem 5rem;
  }
  .popupDesc {
    font-size: 1.6rem;
  }
  .popupInput {
    width: 70%;
  }
  .subscribeInput {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .blogNavigators {
    display: none;
  }

  .popupDialouge {
    width: 70%;

    padding: 4rem 5rem;
  }
  .popupDesc {
    font-size: 1.8rem;
  }
  .popupInput {
    width: 50%;
  }

  .subscribeInput {
    width: 100%;
  }

  .popupFormContainer {
    width: 60%;
  }
}

@media (max-width: 576px) {
  #vmsPrice {
    color: #e53d54;
    margin: 3rem 3rem;
    padding: 2rem 3rem;
    border-radius: 10px;
    margin-top: 10vh;
    background-color: #ffffff;
    text-align: center;
  }
}
